import { forwardRef, Input, InputProps } from '@chakra-ui/react'
import { ErrorMessage, useField } from 'formik'
import React from 'react'
import { ErrorText } from './errorText'

export interface InputFieldProps extends InputProps {
  name: string
}

export const InputField = forwardRef<InputFieldProps, 'input'>(
  ({ name, ...props }, ref) => {
    const [{ onBlur: _, ...field }] = useField(name)
    return (
      <>
        <Input id={name} type="text" mb={1} ref={ref} {...field} {...props} />
        <ErrorMessage name={name} component={ErrorText} />
      </>
    )
  }
)
