import {
  Box,
  HStack,
  InputProps,
  PinInput,
  PinInputField,
  PinInputProps
} from '@chakra-ui/react'
import { ErrorMessage, useField, useFormikContext } from 'formik'
import React from 'react'
import { ErrorText } from './errorText'

export interface TwoFactorTokenFieldProps
  extends InputProps,
    Pick<PinInputProps, 'onComplete'> {
  name: string
}

export const TwoFactorTokenField: React.FC<TwoFactorTokenFieldProps> = ({
  isDisabled,
  name,
  onComplete,
  ...props
}) => {
  const { setFieldValue } = useFormikContext()
  const [{ onChange: _, ...field }] = useField(name)
  return (
    <>
      <HStack {...props}>
        <PinInput
          size={'lg'}
          otp
          autoFocus
          onComplete={onComplete}
          isDisabled={isDisabled}
          {...field}
          // onChange does not take an event but the string itself
          onChange={v => setFieldValue(name, v)}
        >
          <PinInputField fontSize="2xl" />
          <PinInputField fontSize="2xl" />
          <PinInputField fontSize="2xl" />
          <Box
            // Separator
            w={2}
            visibility="hidden"
            display={{ base: 'none', sm: 'block' }}
          />
          <PinInputField fontSize="2xl" />
          <PinInputField fontSize="2xl" />
          <PinInputField fontSize="2xl" />
        </PinInput>
      </HStack>
      <ErrorMessage name={name} component={ErrorText} />
    </>
  )
}
