import { useColorModeValue } from '@chakra-ui/react'

export function useFormIconColors() {
  return {
    gray: 'gray.400',
    green: useColorModeValue('green.500', 'green.400'),
    red: useColorModeValue('red.600', 'red.400')
  }
}

export type FormIconColors = keyof ReturnType<typeof useFormIconColors>
