import { FormHelperText, forwardRef, HelpTextProps } from '@chakra-ui/react'
import React from 'react'

export const FieldHelpText = forwardRef<HelpTextProps, 'div'>((props, ref) => {
  return (
    <FormHelperText
      fontSize="sm"
      color="gray.500"
      mt={0}
      mb={2}
      ref={ref}
      {...props}
    />
  )
})
