import {
  Flex,
  FlexProps,
  FormLabel,
  FormLabelProps,
  forwardRef,
  Text,
  TextProps
} from '@chakra-ui/react'
import React from 'react'

const LabelAside = forwardRef<TextProps, 'aside'>(({ ...props }, ref) => (
  <Text
    as="aside"
    display="block"
    fontSize="xs"
    fontWeight="normal"
    color="gray.600"
    ref={ref}
    {...props}
  />
))

// --

export const Label = forwardRef<FormLabelProps, 'label'>(
  ({ ...props }, ref) => (
    <FormLabel
      // as="label"
      fontWeight="semibold"
      display="inline-flex"
      ref={ref}
      {...props}
    />
  )
)

// --

export interface LabelWithAsideProps extends FlexProps {
  htmlFor?: string
  aside: () => string | React.ReactElement
  asideLeft?: boolean
}

export const LabelWithAside = forwardRef<LabelWithAsideProps, 'label'>(
  ({ children, aside, asideLeft = false, htmlFor, ...props }, ref) => (
    <Flex
      justifyContent={asideLeft ? 'flex-start' : 'space-between'}
      alignItems="baseline"
      ref={ref}
      {...props}
    >
      <Label mb={0}>{children}</Label>
      <LabelAside ml={asideLeft ? -1 : 0}>{aside()}</LabelAside>
    </Flex>
  )
)
