import {
  forwardRef,
  Text,
  TextProps,
  useColorModeValue
} from '@chakra-ui/react'
import React from 'react'

export interface ErrorTextProps extends TextProps {
  bold?: boolean
}

export const ErrorText = forwardRef<ErrorTextProps, 'p'>(
  ({ bold = false, ...props }, ref) => {
    return (
      <Text
        fontSize="xs"
        fontWeight={bold ? 'semibold' : 'normal'}
        color={useColorModeValue('red.600', 'red.400')}
        textAlign="left"
        mb={2}
        ref={ref}
        {...props}
      />
    )
  }
)
