import {
  Box,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps
} from '@chakra-ui/react'
import { FieldMetaProps, useField } from 'formik'
import React from 'react'
import { FiAtSign } from 'react-icons/fi'
import { FormIconColors, useFormIconColors } from './useFormIconColors'

// --

const _getAtSignColor = (meta: FieldMetaProps<string>): FormIconColors => {
  if (!meta.touched) {
    return 'gray'
  }
  if (meta.error) {
    return 'red'
  }
  if (meta.value.length === 0) {
    return 'gray'
  }
  return 'green'
}

// --

export interface EmailFieldProps extends InputProps {
  colorValidation?: boolean
  name?: string
  getAtSignColor?: (meta: FieldMetaProps<string>) => FormIconColors
}

export const EmailField: React.FC<EmailFieldProps> = ({
  colorValidation = false,
  name = 'email',
  getAtSignColor = _getAtSignColor,
  w,
  ...props
}) => {
  const [{ onBlur: _, ...field }, meta] = useField(name)
  const formIconColors = useFormIconColors()
  const atSignColor =
    formIconColors[colorValidation ? getAtSignColor(meta) : 'gray']
  return (
    <>
      <InputGroup w={w}>
        <InputLeftElement pointerEvents="none">
          <Box as={FiAtSign} color={atSignColor} role="img" aria-hidden />
        </InputLeftElement>
        <Input
          id={field.name}
          type="email"
          placeholder="alice@example.com"
          isInvalid={Boolean(meta.touched && meta.error)}
          {...field}
          {...props}
        />
      </InputGroup>
      {meta.touched && <FormErrorMessage>{meta.error}</FormErrorMessage>}
    </>
  )
}
