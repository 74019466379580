import {
  Box,
  FormErrorMessage,
  forwardRef,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  useBoolean,
  useTheme
} from '@chakra-ui/react'
import { useField } from 'formik'
import React from 'react'
import { FiEye, FiEyeOff, FiLock } from 'react-icons/fi'
import { useFormIconColors } from './useFormIconColors'

// --

export interface PasswordFieldProps extends InputProps {
  name: string
  lockColor?: keyof ReturnType<typeof useFormIconColors>
}

export interface ControlledPasswordFieldProps extends PasswordFieldProps {
  revealed: boolean
  toggleRevealed: () => void
}

/**
 * Password input with external state for the clear text reveal feature.
 */
export const ControlledPasswordField = forwardRef<
  ControlledPasswordFieldProps,
  'div'
>(
  (
    { lockColor = 'gray', revealed = false, toggleRevealed, name, ...props },
    ref
  ) => {
    const theme = useTheme()
    const [{ onBlur: _, ...field }, meta] = useField(name)
    const formIconColors = useFormIconColors()
    return (
      <>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <Box
              as={FiLock}
              color={formIconColors[lockColor]}
              role="img"
              aria-hidden
            />
          </InputLeftElement>
          <Input
            id={field.name}
            fontFamily={
              revealed && field.value.length > 0 // checking length to avoid mono-fonting the placeholder
                ? theme.fonts.mono
                : 'inherit'
            }
            transition="border 0.2s ease"
            type={revealed ? 'text' : 'password'}
            placeholder="••••••••••••••••"
            isInvalid={Boolean(meta.touched && meta.error)}
            pr={8}
            mb={meta.touched && meta.error ? 0 : 2}
            ref={ref}
            {...field}
            {...props}
          />
          <InputRightElement>
            <IconButton
              aria-label={revealed ? 'Hide password' : 'Show password'}
              icon={revealed ? <FiEyeOff /> : <FiEye />}
              variant="ghost"
              onClick={toggleRevealed}
            />
          </InputRightElement>
        </InputGroup>
        {meta.touched && (
          <FormErrorMessage mb={2}>{meta.error}</FormErrorMessage>
        )}
      </>
    )
  }
)

/**
 * Password input with internal state for the clear text reveal feature.
 */
export const PasswordField = forwardRef<PasswordFieldProps, 'div'>(
  ({ ...props }, ref) => {
    const [revealed, { toggle }] = useBoolean(false)
    return (
      <ControlledPasswordField
        revealed={revealed}
        toggleRevealed={toggle}
        ref={ref}
        {...props}
      />
    )
  }
)
